<!-- Btra Botones Extra -->

<template>
    <div class="btraextra">
      <btra
          v-if="btra_particular"
          :btra_particular="mount_btra"
          :btra_modulo="btra_modulo"
          @onEvent="event_btraextra">
      </btra>
    </div>
</template>



<script>

    import plugs from "@/common/general_plugs";
    const btra = () => plugs.groute("btra.vue", "comp");

    export default {
      components: { btra },
      props: {
        btra_permisos: { type: [Array, Object], required:true },
        btra_particular: { type: [Object, String], required:true },
        btra_modulo: { type: String, default: "m" }
      },

      computed: {

        // monto array de botones
        mount_btra() 
        {
          // extraigo la btra Extra
          var bp= this.get_btraExtra();
       
          // filtro array de botones por sus permisos
          return this.filtrar_btns(bp);
        }

      },

      methods: {

        // gestor de eventos
        event_btraextra(evt) {
          console.log("*** event_btraextra. Emit btraextra: ", evt, " ***");
          this.$emit("onEvent", { accion: evt.accion });
        },

        // según btra_modulo, extraigo el array de btns extra de la btra particular
        get_btraExtra() 
        {
          if (!this.btra_modulo) return [];

          // copio btra particular
          let bp = JSON.parse(JSON.stringify(this.pass_btra_toObject()));
          let btra_particular = [];

          // obtengo el array de btns extra según el tipo de módulo recibido
          switch (this.btra_modulo) {
            case "mrow":
            case "mtop":
              if (bp.row["extra"] && bp.row["extra"] !== false) btra_particular = bp.row.extra;
              if (bp.row["extraer"] && bp.row["extraer"] !== false) btra_particular.push({ accion: "extraer", btn: "btn_extraer", show: 1 });
              break;

            case "m":
            case "mextra":
            case "detalle":
            case "docs":
            case "footer":
            case "top":
            case "R":              
              if (bp.footer["extra"] && bp.footer["extra"] !== false) btra_particular= bp.footer.extra;
              break;

            default:
              return [];
          }

          return btra_particular;          
        },

        // filtro botón por su permiso
        filtrar_btns(btns) 
        {          
          btns.forEach(elem => {
            // si es un extra de un row, le añadimos 'row' al nombre del btn
            if (this.btra_modulo === "mrow") elem.btn = elem.btn + "_row";

            // si la acción es numérica, cogemos el permiso del array de Mto
            if (typeof elem.accion === "number") {
              elem.show = Number(this.btra_permisos.mto[elem.accion]);
              return;
            }

            // cogemos el permiso del array de botones Extra
            if (elem.show && this.btra_permisos.extra[elem.accion]) {
              elem.show = Number(this.btra_permisos.extra[elem.accion]);
              return;
            }

          });
         
          return btns;
        },

        // convierto la btra particular recibida como String en un Objeto
        pass_btra_toObject() {          
          if (typeof this.btra_particular === "object") return this.btra_particular;        
          return this.$cfg.btra_tipo[this.btra_particular];        
        }
      }
    };
</script>
